import { useEffect, useContext } from 'react'
import GameContext from './GameContext'
import { useHistory } from 'react-router-dom'

export default (): void => {
  const {
    iconFoundList,
    difficulty,
    setIsPaused,
    onPauseTimer,
    hoursText,
    minutesText,
    secondsText,
    milisecondsText,
  } = useContext(GameContext)
  const history = useHistory()

  useEffect(() => {
    if (iconFoundList.length === difficulty / 2) {
      // setIsShowingWinModal(true)
      setIsPaused(true)
      onPauseTimer()
      const originalString = `${hoursText}:${minutesText}:${secondsText}.${milisecondsText}`
      const encodedString = btoa(originalString) // Encode to Base64
      localStorage.setItem('fbErkT', encodedString)
      history.push('/ranking')
    }
  }, [
    difficulty,
    iconFoundList.length,
    onPauseTimer,
    setIsPaused,
    // setIsShowingWinModal,
  ])
}
