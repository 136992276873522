import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  OutlinedButton,
  SloganContainer,
  StyledInput,
  VoteButton,
} from './styles'
import logo1x from '../../assets/erkado-logo.png'
import logo2x from '../../assets/erkado-logo@2x.png'
import gra from '../../assets/gra.png'
import dalmierz from '../../assets/dalmierz.png'
import facebook from '../../assets/facebook.png'
import instagram from '../../assets/instagram.png'
import star from '../../assets/star.png'
import AlertModal from '../../components/AlertModal'
import axios from 'axios'
import { NormalButton } from '../Register/styles'
import Loading from '../../components/Loading'

const Home: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const history = useHistory()
  const [email, setEmail] = useState<string>('')
  const [modalMessage, setModalMessage] = useState<string>('')
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [busy, setBusy] = useState<boolean>(false)
  const [timerLoading, setTimerLoading] = useState(true) // Loading state
  const [isContestFinished, setIsContestFinished] = useState<boolean>(false)

  const onVote = (): void => {
    history.push('/vote')
  }

  const onPlay = (): void => {
    history.push('/splash')
  }

  const handleLogout = () => {
    localStorage.removeItem('fbErkU')
    localStorage.removeItem('fbErkA')
    localStorage.removeItem('fbErkB')
    localStorage.removeItem('fbErkF')
    localStorage.removeItem('fbErkP')
    localStorage.removeItem('fbErkWin')
    setIsUserLoggedIn(false)
    setUsername('')
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // Validate email using regular expression
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!email || !emailRegex.test(email)) {
      setModalMessage('Niepoprawny adres e-mail')
      setIsShowingModal(true)
    } else {
      try {
        setBusy(true)
        const loginResponse = await axios.post(`${API_URL}/game/login`, {
          username: email,
        })

        if (loginResponse.data.token) {
          localStorage.setItem('fbErkU', loginResponse.data.token)
          history.push('/splash')
          setBusy(false)
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                setModalMessage('Osoba o takim adresie nie istnieje w bazie.')
                setIsShowingModal(true)
                break

              default:
                setModalMessage('Ups... coś poszło nie tak. Spróbuj ponownie.')
                setIsShowingModal(true)
                break
            }
          }
        }
        setBusy(false)
      }
    }
  }

  useEffect(() => {
    axios
      .get(`${API_URL}/api/main/ranking/list`)
      .then((response) => {
        const dateStrings: string[] = response.data.rankings.map(
          (ranking: { awardDate: string }) => ranking.awardDate,
        )

        // Convert date strings to Date objects
        const dates = dateStrings.map(
          (dateString: string) => new Date(dateString),
        )

        // Get the current date
        const currentDate = new Date()

        // Check if all dates are in the past
        // setIsContestFinished(dates.every((date) => date < currentDate))
        setIsContestFinished(true)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
      .finally(() => {
        setTimerLoading(false)
      })
  }, [API_URL])

  const decodeToken = (token: string) => {
    const base64Url = token.split('.')[1] // JWTs are typically in the format: header.payload.signature
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/') // Replace URL-safe characters
    const decoded = decodeURIComponent(escape(atob(base64))) // Decode and convert to a string

    return JSON.parse(decoded) // Parse the JSON payload
  }

  useEffect(() => {
    const token = localStorage.getItem('fbErkU')
    localStorage.removeItem('fbErkT')
    localStorage.removeItem('fbErkV')

    if (!token) {
      handleLogout()
    } else {
      try {
        const decodedToken: any = decodeToken(token)
        const expirationTime = decodedToken.exp * 1000 // Convert expiration time to milliseconds.

        // Compare the token's expiration time with the current time.
        const isTokenValid = Date.now() < expirationTime

        if (!isTokenValid) {
          // Token has expired, clear it and log the user out.
          handleLogout()
        }

        setIsUserLoggedIn(true)
        setUsername(decodedToken.username)
      } catch (error) {
        // Token decoding or parsing error, log the user out as a safety measure.
        handleLogout()
      }
    }
  }, [])

  return (
    <Container>
      <img
        src={logo1x}
        srcSet={`${logo1x} 1x, ${logo2x} 2x`}
        alt=""
        style={{ maxWidth: '100%', marginTop: '30px', marginBottom: '5px' }}
      />
      <SloganContainer>
        <div style={{ marginRight: '15px' }}>
          <span style={{ color: '#C51431' }}>DZIĘKUJEMY</span>
          <br />
          za udział w Konkursie!
        </div>
        <img src={star} alt="" style={{}} />
      </SloganContainer>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          height: 'calc(100vh - 340px)',
          justifyContent: 'space-around',
          marginTop: '20px',
        }}
      >
        <img
          src={gra}
          alt=""
          style={{ position: 'absolute', height: '100%', right: '-200px' }}
        />
        <img
          src={dalmierz}
          alt=""
          style={{ position: 'absolute', height: '100%', left: '-60px' }}
        />
      </div>
      {timerLoading && <Loading />}
      {!timerLoading && isContestFinished && (
        <div
          style={{
            marginLeft: '20px',
            marginRight: '20px',
            textAlign: 'center',
            marginBottom: '30px',
            maxWidth: '600px',
            marginTop: '-160px',
            zIndex: 9999,
          }}
        >
          <VoteButton style={{ backgroundColor: 'rgb(49, 49, 49)' }}>
            <strong>
              Dziękujemy za wzięcie udziału w konkursie, zwycięzców o wygranej
              powiadomimy drogą mailową.
            </strong>
            <br />
            <br />
            Śledź nas na{' '}
            <a
              href="https://www.facebook.com/Erkado/"
              target="_blank"
              style={{ color: 'white', textDecoration: 'underline' }}
            >
              Facebooku
            </a>{' '}
            - kolejne konkursy i zabawy z nagrodami już wkrótce!
            <br />
            <a href="https://www.facebook.com/Erkado/" target="_blank">
              <img src={facebook} alt="" />
            </a>
            <br />
            Jeśli spodobała Ci się nasza zabawa możesz zagrać ponownie.
            <br />
          </VoteButton>
          <br />
          <br />
          <VoteButton onClick={onPlay}>Zagraj jeszcze raz</VoteButton>
        </div>
      )}
      {!isUserLoggedIn && !timerLoading && !isContestFinished && (
        <>
          <VoteButton onClick={onVote}>Głosuję i gram o dalmierz</VoteButton>
          <div
            style={{
              textAlign: 'center',
              marginTop: '15px',
              marginBottom: '5px',
            }}
          >
            Jeśli jesteś już zarejestrowany, podaj swój adres.
          </div>
          <form onSubmit={handleSubmit}>
            <StyledInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail"
              style={{ marginRight: 5 }}
            />
            <OutlinedButton type="submit" disabled={busy}>
              Zaloguj
            </OutlinedButton>
          </form>
        </>
      )}
      {isUserLoggedIn && !timerLoading && !isContestFinished && (
        <div style={{ textAlign: 'center' }}>
          Jesteś zalogowany jako:
          <br />
          <strong style={{ color: '#C51431' }}>{username}</strong>
          <br />
          <NormalButton onClick={onPlay} style={{ marginTop: 10 }}>
            Graj
          </NormalButton>
          <OutlinedButton
            onClick={handleLogout}
            style={{ marginTop: 10, marginBottom: 20 }}
          >
            Wyloguj
          </OutlinedButton>
        </div>
      )}
      <AlertModal isShowing={isShowingModal}>
        <>
          <p>{modalMessage}</p>
          <br />
          <OutlinedButton onClick={() => setIsShowingModal(false)}>
            Zamknij
          </OutlinedButton>
        </>
      </AlertModal>
    </Container>
  )
}

export default Home
