import { useContext } from 'react'
import { useArrayShuffle } from '../../hooks'
import GameContext from './GameContext'
import { GameCardType } from '../../types/GameConfig'

type ClearGameStateFunction = () => void

export default (): ClearGameStateFunction => {
  const {
    setIconList,
    setIconFoundList,
    setFirstSelectedCard,
    setSecondSelectedCard,
    difficulty,
  } = useContext(GameContext)

  const onShuffleArray = useArrayShuffle<GameCardType>()

  const getCurrentDate = () => {
    // Create a new Date object to get the current date and time
    const currentDate = new Date()

    // Extract the date and time components
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are 0-indexed
    const day = String(currentDate.getDate()).padStart(2, '0')
    const hours = String(currentDate.getHours()).padStart(2, '0')
    const minutes = String(currentDate.getMinutes()).padStart(2, '0')
    const seconds = String(currentDate.getSeconds()).padStart(2, '0')

    // Combine the components into the desired format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  const onClearGameState = (): void => {
    setIconFoundList([])
    // setIsPaused(true)
    // setCountdown(3)
    setFirstSelectedCard(-1)
    setSecondSelectedCard(-1)
    // onStopTimer()

    const cachedCardListFromLocalStorage = localStorage.getItem('fbCardList')
    if (cachedCardListFromLocalStorage) {
      const parsedCardList = JSON.parse(cachedCardListFromLocalStorage)
      const cardsWithImagesFromLocalStorage: GameCardType[] = []
      for (let i = 0; i < parsedCardList.length; i++) {
        const cardId = parsedCardList[i].id
        const cardData = localStorage.getItem(`fb_erk_card_${cardId}`)
        if (cardData) {
          cardsWithImagesFromLocalStorage.push(JSON.parse(cardData))
        }
      }
      // load here cardsWithImages from Redux
      const shuffledArray = onShuffleArray(cardsWithImagesFromLocalStorage)
      // Shuffle 'img1' and 'img2' properties within each object
      shuffledArray.forEach((item) => {
        if (Math.random() < 0.5 && item.img2) {
          ;[item.img1, item.img2] = [item.img2, item.img1]
        }
      })

      let correctLengthArray = shuffledArray.slice(0, difficulty / 2)
      const doubledLengthArray = correctLengthArray.concat(correctLengthArray)
      const correctLengthSuffledArray = onShuffleArray(doubledLengthArray)
      setIconList(correctLengthSuffledArray)
      const selectedCards = correctLengthArray
        .map((c) => c.id)
        .sort((a, b) => a - b)
      localStorage.setItem('fbErkC', JSON.stringify(selectedCards))
      localStorage.setItem('fbErkD', getCurrentDate())
    }
  }

  return onClearGameState
}
