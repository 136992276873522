import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Container,
  GridContainer,
  GridItem,
  VoteButton,
  VoteButtonContainer,
} from './styles'
import axios from 'axios'
import VoteOptionImage from './VoteOptionImage'
import Header from '../../components/Header'
import Loading from '../../components/Loading'

interface ParamTypes {
  id: string
}

const Vote: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  let { id } = useParams<ParamTypes>()
  const history = useHistory()
  const [voteOptions, setVoteOptions] = useState<
    { id: number; name: string }[]
  >([])
  const [loading, setLoading] = useState(true) // Loading state
  const [saving, setSaving] = useState(false)
  const [selectedVoteOptionId, setSelectedVoteOptionId] = useState<
    number | null
  >(parseInt(id) || null)

  const voteOptionsRef = useRef<Array<HTMLDivElement | null>>([])

  // Initialize voteOptionsRef when the component mounts
  useEffect(() => {
    voteOptionsRef.current = new Array(voteOptions.length)
  }, [voteOptions])

  useEffect(() => {
    // Wait for the loading to be false and the selectedVoteOptionId to be set
    if (
      !loading &&
      selectedVoteOptionId &&
      voteOptionsRef.current[selectedVoteOptionId - 1]
    ) {
      const selectedOption = voteOptionsRef.current[selectedVoteOptionId - 1]
      if (selectedOption) {
        selectedOption.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [loading, selectedVoteOptionId])

  useEffect(() => {
    axios
      .get(`${API_URL}/api/main/vote-option/list`)
      .then((response) => {
        setVoteOptions(response.data.voteOptions)
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
        setLoading(false) // Set loading to false in case of an error
      })
  }, [API_URL])

  const handleOptionChoose = (id: number) => {
    if (id === selectedVoteOptionId) {
      setSelectedVoteOptionId(null)
      history.replace('/vote')
    } else {
      setSelectedVoteOptionId(id)
      history.replace(`/vote/${id}`)
    }
  }

  const onVoteClick = async (): Promise<void> => {
    if (selectedVoteOptionId) {
      try {
        setSaving(true)
        await axios.post(`${API_URL}/api/vote`, {
          voteOptionId: selectedVoteOptionId,
        })
        localStorage.setItem('fbErkV', selectedVoteOptionId.toString())
        history.push('/splash')
      } catch (error) {
        console.log(error)
      } finally {
        setSaving(false)
      }
    }
  }

  return (
    <Container>
      <Header />
      <p style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        Wybierz najpiękniejsze drzwi i zagłosuj na{' '}
        <span style={{ color: '#C51431', fontWeight: 600 }}>
          Gwiazdę Erkado
        </span>
        :
      </p>
      {loading && (
        <div style={{ marginTop: '40px' }}>
          <Loading />
        </div>
      )}
      {!loading && (
        <>
          <GridContainer>
            {voteOptions.map((option, index) => (
              <GridItem
                key={option.id}
                onClick={() => handleOptionChoose(option.id)}
                ref={(el) => (voteOptionsRef.current[index] = el)}
              >
                <VoteOptionImage
                  id={option.id}
                  selected={option.id === selectedVoteOptionId}
                />
                {option.name}
              </GridItem>
            ))}
          </GridContainer>
          <VoteButtonContainer>
            <VoteButton
              disabled={!selectedVoteOptionId || saving}
              onClick={onVoteClick}
            >
              Głosuję
            </VoteButton>
          </VoteButtonContainer>
        </>
      )}
    </Container>
  )
}

export default Vote
