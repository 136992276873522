import React, { useEffect, useState } from 'react'
import {
  Container,
  FormInputs,
  InputContainer,
  NormalButton,
  OutlinedButton,
  Select,
  StyledInput,
} from './styles'
import Header from '../../components/Header'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import AgreementCheckboxes, {
  Agreement,
} from '../../components/AgreementCheckboxes'
import Loading from '../../components/Loading'
import AlertModal from '../../components/AlertModal'
import InputMask from 'react-input-mask'

type Segment = {
  id: number
  name: string
  code: string
  description: string | null
}

const Register: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const [loading, setLoading] = useState(true)
  const [firstname, setFirstname] = useState<string>('')
  const [lastname, setLastname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [segment, setSegment] = useState<string>('')
  const [voteOptionId, setVoteOptionId] = useState<number | null>(null)

  const [segments, setSegments] = useState<Segment[]>([])

  const [originalString, setOriginalString] = useState<string>('')
  const history = useHistory()

  const [isShowingModal, setIsShowingModal] = useState<boolean>(false)
  const [saving, setSaving] = useState<boolean>(false)
  const [modalMessage, setModalMessage] = useState<string>('')
  const [modalButtonText, setModalButtonText] = useState<string>('')

  const onPlayAgain = (): void => {
    localStorage.removeItem('fbErkT')
    history.push('/splash')
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (firstname.trim().length === 0) {
      setIsShowingModal(true)
      setModalMessage('Uzupełnij imię.')
      setModalButtonText('Popraw')
      return
    }
    if (lastname.trim().length === 0) {
      setIsShowingModal(true)
      setModalMessage('Uzupełnij nazwisko.')
      setModalButtonText('Popraw')
      return
    }
    // Validate email using regular expression
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!email || !emailRegex.test(email)) {
      setIsShowingModal(true)
      setModalMessage('Adres e-mail jest niepoprawny.')
      setModalButtonText('Popraw')
      return
    }
    if (!phone || (phone.replace(/[^0-9]/g, '').length !== 11)) {
      setIsShowingModal(true)
      setModalMessage('Uzupełnij numer telefonu.')
      setModalButtonText('Popraw')
      return
    }
    // if (!segment) {
    //   setIsShowingModal(true)
    //   setModalMessage('Wybierz segment.')
    //   setModalButtonText('Popraw')
    //   return
    // }
    const requiredAgreementsAreChecked = agreements
      .filter((ag) => ag.isRequired)
      .every((agreement) => agreement.checked)
    if (!requiredAgreementsAreChecked) {
      setIsShowingModal(true)
      setModalMessage('Zaznacz wymagane zgody.')
      setModalButtonText('Popraw')
      return
    }

    const formData = {
      firstname,
      lastname,
      email,
      mobile: phone ? phone : null,
      // segmentId: parseInt(segment),
      voteOptionId,
      agreements: agreements.map((agreement) => ({
        id: agreement.id,
        isAccepted: agreement.checked,
      })),
    }

    try {
      setSaving(true)
      const response = await axios.post(`${API_URL}/api/register`, formData)

      if (response.data.token) {
        localStorage.setItem('fbErkU', response.data.token)
        history.push('/ranking')
        setSaving(false)
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              setModalMessage(
                'Osoba o takim adresie e-mail już istnieje w bazie.',
              )
              setModalButtonText('Zamknij')
              setIsShowingModal(true)
              break

            default:
              setModalMessage('Ups... coś poszło nie tak. Spróbuj ponownie.')
              setModalButtonText('Zamknij')
              setIsShowingModal(true)
              break
          }
        }
      }
      setSaving(false)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('fbErkU')

    if (token) {
      history.push('/ranking')
    }

    const encodedString = localStorage.getItem('fbErkT')
    const optionId = localStorage.getItem('fbErkV')
    if (optionId) {
      setVoteOptionId(parseInt(optionId))
    }
    if (encodedString) {
      const decoded = atob(encodedString)
      setOriginalString(decoded.slice(3))
    } else {
      history.push('/')
    }
  }, [])

  const [agreements, setAgreements] = useState<Agreement[]>([])

  useEffect(() => {
    const getAgreementsAndSegments = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/main/agreement/list`)
        setAgreements(
          response.data.agreements.map((agreement: Agreement) => ({
            ...agreement,
            checked: false,
          })),
        )
        const segmentsResponse = await axios.get(
          `${API_URL}/api/main/segment/list`,
        )
        setSegments(segmentsResponse.data.segments)
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false)
      }
    }
    getAgreementsAndSegments()
  }, [API_URL])

  const handleAgreementChange = (agreement: Agreement, isChecked: boolean) => {
    setAgreements((prevState) =>
      prevState.map((a) =>
        a.code === agreement.code
          ? {
              ...a,
              checked: isChecked,
            }
          : a,
      ),
    )
  }

  return (
    <Container>
      <Header />
      <h2 style={{ textAlign: 'center' }}>
        Twój wynik: <span style={{ color: '#C51431' }}>{originalString}</span>
      </h2>
      <br />
      <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
        Aby zapisać Twój wynik w rankingu i <strong>odebrać nagrodę</strong>{' '}
        konieczne jest podanie danych.
      </h3>
      {loading && <Loading />}
      {!loading && (
        <form
          onSubmit={handleSubmit}
          style={{
            margin: '20px 0 10px 0',
            paddingLeft: '10px',
            paddingRight: '10px',
            width: '100%',
            maxWidth: '600px',
            textAlign: 'center',
          }}
        >
          <FormInputs>
            <StyledInput
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              placeholder="Imię *"
            />
            <StyledInput
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              placeholder="Nazwisko *"
            />
          </FormInputs>
          <div>
            <StyledInput
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-mail *"
            />
          </div>
          {/* <FormInputs> */}
          <div style={{ marginBottom: '15px' }}>
            <InputMask
              mask="+48 999 999 999"
              value={phone}
              onChange={(e: {
                target: { value: React.SetStateAction<string> }
              }) => setPhone(e.target.value)}
              placeholder="Telefon *"
              style={{ 
                border: '1px solid #bebebe',
                borderRadius: '30px',
                padding: '8px',
                textAlign: 'center',
                width: '100%',
                marginBottom: '10px',
               }}
            />
          </div>
          {/* <Select
              onChange={(e) => {
                setSegment(e.target.value)
              }}
            >
              <option value="">Segment *</option>
              {segments.map((segment) => (
                <option key={segment.code} value={segment.id}>
                  {segment.name}
                </option>
              ))}
            </Select>
          </FormInputs> */}
          <div style={{ textAlign: 'left', paddingBottom: 10 }}>
            * pola wymagane
          </div>
          <AgreementCheckboxes
            agreements={agreements}
            onAgreementChange={handleAgreementChange}
          />
          <NormalButton type="submit" disabled={saving}>
            Potwierdzam
          </NormalButton>
          <OutlinedButton
            style={{
              width: '80%',
              maxWidth: '600px',
              marginBottom: 20,
              marginTop: 10,
            }}
            onClick={onPlayAgain}
            disabled
          >
            Chcę zagrać jeszcze raz
          </OutlinedButton>
        </form>
      )}
      <AlertModal isShowing={isShowingModal}>
        <>
          <p>{modalMessage}</p>
          <br />
          <OutlinedButton onClick={() => setIsShowingModal(false)}>
            {modalButtonText}
          </OutlinedButton>
        </>
      </AlertModal>
    </Container>
  )
}

export default Register
