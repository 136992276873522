import React, { useEffect, useState } from 'react'
import { Container, OutlinedButton, RankingItem } from './styles'
import Header from '../../components/Header'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Loading from '../../components/Loading'
import AwardsTimer from '../../components/AwardsTimer'

type RankingResult = {
  rankPlace: number
  firstname: string
  elapsedTime: number
}

const Ranking: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL

  const [loading, setLoading] = useState(true)

  const [originalString, setOriginalString] = useState<string>('')
  const [awardDates, setAwardDates] = useState<string[]>([])
  const [elapsedTime, setElapsedTime] = useState<number | null>(null)
  const [rankingResults, setRankingResults] = useState<RankingResult[]>([])
  const [isContestFinished, setIsContestFinished] = useState<boolean>(true)
  const history = useHistory()

  const onPlayAgain = (): void => {
    localStorage.removeItem('fbErkT')
    history.push('/splash')
  }

  const onLogout = (): void => {
    localStorage.removeItem('fbErkU')
    localStorage.removeItem('fbErkA')
    localStorage.removeItem('fbErkB')
    localStorage.removeItem('fbErkF')
    localStorage.removeItem('fbErkP')
    localStorage.removeItem('fbErkWin')
    history.push('/')
  }

  useEffect(() => {
  //   const loadData = async (elapsedTime: number) => {
  //     try {
  //       const cards = localStorage.getItem('fbErkC')
  //       const gameDate = localStorage.getItem('fbErkD')
  //       const token = localStorage.getItem('fbErkU')

  //       if (cards && gameDate && token) {
  //         const userGameResponse = await axios.post(
  //           `${API_URL}/api/user-game`,
  //           {
  //             gameDate,
  //             cards: JSON.parse(cards),
  //             elapsedTime,
  //           },
  //           {
  //             headers: { Authorization: `Bearer ${token}` },
  //           },
  //         )

  //         if (userGameResponse.data.success === true) {
  //           localStorage.removeItem('fbErkC')
  //           localStorage.removeItem('fbErkD')

  //           localStorage.setItem(
  //             'fbErkB',
  //             userGameResponse.data.userBest.toString(),
  //           )
  //           localStorage.setItem(
  //             'fbErkP',
  //             userGameResponse.data.rankPlace.toString(),
  //           )
  //           localStorage.setItem(
  //             'fbErkA',
  //             userGameResponse.data.rankingId.toString(),
  //           )
  //           localStorage.setItem('fbErkF', userGameResponse.data.userFirstname)
  //         } else {
  //           localStorage.setItem(
  //             'fbErkWin',
  //             userGameResponse.data.hasWon.toString(),
  //           )
  //         }
  //       }

  //       const response = await axios.get(`${API_URL}/api/main/ranking/list`)

  //       const dateStrings: string[] = response.data.rankings.map(
  //         (ranking: { awardDate: string }) => ranking.awardDate,
  //       )
  //       setAwardDates(dateStrings)

  //       // Convert date strings to Date objects
  //       const dates = dateStrings.map(
  //         (dateString: string) => new Date(dateString),
  //       )

  //       // Get the current date
  //       const currentDate = new Date()

  //       // Check if all dates are in the past
  //       setIsContestFinished(dates.every((date) => date < currentDate))

  //       const rankingReponse = await axios.get(`${API_URL}/api/main/ranking`, {
  //         headers: { Authorization: `Bearer ${token}` },
  //       })
  //       setRankingResults(rankingReponse.data.results)
  //     } catch (error) {
  //       console.error('Error fetching data:', error)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }

    const encodedString = localStorage.getItem('fbErkT')
    // const token = localStorage.getItem('fbErkU')
    // if (encodedString && token) {
    if (encodedString) {
      const decoded = atob(encodedString)
      setOriginalString(decoded.slice(3))
      const milliseconds = timeStringToMilliseconds(decoded)
      setElapsedTime(milliseconds)
      // loadData(milliseconds)
    } else {
      // history.push('/')
    }
  }, [])

  const timeStringToMilliseconds = (timeString: string) => {
    const [hours, minutes, secondsAndMillis] = timeString.split(':')
    const [seconds, milliseconds] = secondsAndMillis.split('.')

    const totalMilliseconds =
      parseInt(hours, 10) * 3600000 + // 1 hour = 3600000 milliseconds
      parseInt(minutes, 10) * 60000 + // 1 minute = 60000 milliseconds
      parseInt(seconds, 10) * 1000 + // 1 second = 1000 milliseconds
      parseInt(milliseconds, 10)

    return totalMilliseconds
  }

  const formatDuration = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000)
    // const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const seconds = totalSeconds % 60
    const millisecondsRemaining = milliseconds % 1000

    const formattedTime = `${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}.${String(millisecondsRemaining).padStart(2, '0')}`

    return formattedTime
  }

  return (
    <Container>
      <Header />
      {isContestFinished && (
        <>
          <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
            Twój wynik:{' '}
            <strong style={{ color: '#C51431' }}>{originalString}</strong>
          </h3>
          <OutlinedButton
            style={{
              width: '80%',
              maxWidth: '600px',
              marginBottom: 20,
              marginTop: 10,
            }}
            onClick={onPlayAgain}
          >
            Chcę zagrać jeszcze raz
          </OutlinedButton>
        </>
      )}

      {/* {!loading && !isContestFinished && (
        <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
          Podsumowanie rankingu i przyznanie nagród:
          <br />
          <span style={{ color: '#C51431' }}>
            <AwardsTimer awardDates={awardDates} />
          </span>
        </h3>
      )}
      {!loading && isContestFinished && (
        <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
          Konkurs zakończył się, organizator skontaktuje się ze zwycięzcami.
        </h3>
      )}
      <br />
      <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
        Twój wynik:{' '}
        <strong style={{ color: '#C51431' }}>{originalString}</strong>
      </h3>
      {localStorage.getItem('fbErkA') &&
        localStorage.getItem('fbErkP') &&
        localStorage.getItem('fbErkB') && (
          <h5 style={{ fontWeight: 'normal', textAlign: 'center' }}>
            Twój najlepszy wynik:{' '}
            <strong style={{ color: '#C51431' }}>
              {formatDuration(parseInt(localStorage.getItem('fbErkB') || ''))}{' '}
              (miejsce {localStorage.getItem('fbErkP')})
            </strong>
          </h5>
        )}
      <br />
      {localStorage.getItem('fbErkWin') &&
        localStorage.getItem('fbErkWin') === 'true' && (
          <div
            style={{
              padding: '6px 16px',
              color: 'rgb(102, 60, 0)',
              border: '1px solid rgb(255, 152, 0)',
              background: '#fff',
              borderRadius: '12px',
              margin: '0 15px 20px 15px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Ze względu na wygraną w poprzednich turach, Twoje wyniki nie
            zapisują się w aktualnym rankingu.
          </div>
        )}
      <h3 style={{ fontWeight: 'normal', textAlign: 'center' }}>
        Ranking 10 najszybszych graczy
      </h3>
      {loading && <Loading />}
      {!loading && (
        <div style={{ marginTop: 5, width: '100%', textAlign: 'center' }}>
          {rankingResults.slice(0, 10).map((result: RankingResult) => (
            <RankingItem
              key={`result-${result.rankPlace}`}
              style={
                result.rankPlace ===
                parseInt(localStorage.getItem('fbErkP') || '0')
                  ? {
                      background: '#C51431',
                      borderColor: '#C51431',
                      color: '#fff',
                    }
                  : {}
              }
            >
              <div>
                {result.rankPlace}. {result.firstname}
              </div>
              <div>{formatDuration(result.elapsedTime)}</div>
            </RankingItem>
          ))}
          {parseInt(localStorage.getItem('fbErkP') || '0') > 10 && (
            <>
              <div style={{ marginBottom: 7 }}>...</div>
              <RankingItem
                style={{
                  background: '#C51431',
                  borderColor: '#C51431',
                  color: '#fff',
                }}
              >
                <div>
                  {localStorage.getItem('fbErkP')}.{' '}
                  {localStorage.getItem('fbErkF')}
                </div>
                <div>
                  {formatDuration(
                    parseInt(localStorage.getItem('fbErkB') || ''),
                  )}
                </div>
              </RankingItem>
            </>
          )}
          <OutlinedButton
            style={{
              width: '80%',
              maxWidth: '600px',
              marginBottom: 20,
              marginTop: 10,
            }}
            onClick={onPlayAgain}
          >
            Chcę zagrać jeszcze raz
          </OutlinedButton>
          <div>
            <OutlinedButton
              onClick={onLogout}
              style={{ marginBottom: 20, fontSize: '80%' }}
            >
              Wyloguj
            </OutlinedButton>
          </div>
        </div>
      )} */}
    </Container>
  )
}

export default Ranking
